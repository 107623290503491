<template>
    <div class="subscription-management">
      <h1>Subscription Status</h1>
      <div class="subscription-status">
        <p v-if="stripeStatus === 'no_subscription'">
          Your account does not have an active subscription. To continue using our services, please subscribe.
        </p>
        <p v-if="stripeStatus !== 'active' && stripeStatus !== 'no_subscription'">
          Your subscription is currently inactive. Please activate your subscription to get access to all features.
        </p>
        <p v-if="stripeStatus === 'active'">
          Your subscription is active. You can manage your subscription and billing information below.
        </p>
      </div>
  
      <div class="subscription-actions">
        <button
          v-if="stripeStatus === 'no_subscription' || stripeStatus !== 'active'"
          @click="redirectToCheckout"
          class="btn btn-green"
        >
          Subscribe / Reactivate
        </button>
        <button
          v-if="stripeStatus === 'active'"
          @click="redirectToBillingPortal"
          class="btn btn-green"
        >
          Manage Billing
        </button>
        <button
          v-if="stripeStatus === 'active'"
          @click="goToDashboard"
          class="btn btn-green"
        >
          Go to Articles Dashboard
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
import { mapGetters } from "vuex";
  
  // Define or import the checkUserAuthentication function
  function checkUserAuthentication() {
      return axios.post('/accounts/user/validate-authentication', {}, {withCredentials: true})
          .then(response => {
              return response;
          })
          .catch(error => {
              console.error('Error during authentication check:', error);
              throw error;
          });
  }
  
  export default {
    data() {
      return {
        stripeStatus: null,
      };
    },
    computed: {
      ...mapGetters('accounts', ['getCurrentUser']),
      accountDetails() {
        return this.$store.state.accounts.accountDetails;
      },
    },
    mounted() {
      this.initialize();
    },
    created() {
        this.initialize();
    },
    methods: {
      initialize() {
        // Call the checkUserAuthentication function
        checkUserAuthentication()
          .then(response => {
            // Handle the response by updating the store and component state
            this.$store.dispatch('accounts/setAccountDetails', response.data);
  
            // Update the stripeStatus based on the response
            this.stripeStatus = response.data.stripe_status;
          })
          .catch(error => {
            // Handle any errors (e.g., redirect to login)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'Login' });
            }
          })
          .finally(() => {
            // Ensure to reset the application loading state
            this.$store.dispatch('application/setApplicationLoadingState', false);
          });
      },
      redirectToCheckout() {
        axios.post('/accounts/api/stripe/create-checkout-session/', {}, { withCredentials: true })
          .then(response => {
            window.location.href = response.data.checkout_url;
          })
          .catch(error => {
            console.error('Error creating checkout session:', error);
            this.$store.dispatch('notifications/pushNotification', {
              message: 'An error occurred while trying to create a checkout session.',
              type: 'danger'
            });
          });
      },
      redirectToBillingPortal() {
        axios.post('/accounts/api/stripe/create-billing-portal-session/', {}, { withCredentials: true })
          .then(response => {
            window.location.href = response.data.billing_portal_url;
          })
          .catch(error => {
            console.error('Error creating billing portal session:', error);
            this.$store.dispatch('notifications/pushNotification', {
              message: 'An error occurred while trying to create a billing portal session.',
              type: 'danger'
            });
          });
      },
      goToDashboard() {
        this.$router.push({ name: 'Home' });
      },
    },
  };
  </script>
  
  <style scoped>
  .subscription-management {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
  }
  
  .subscription-management h1 {
    font-size: 28px;
    margin-bottom: 30px;
    color: #333;
  }
  
  .subscription-status p {
    font-size: 18px;
    margin-bottom: 20px;
    color: #555;
  }
  
  .subscription-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
  }
  </style>
  